import { IUserEventEmitterFactory, UserEventSource } from '@foxtail-dev/user-clients/dist/lib/UserEventSource'
import { Logger } from './Logger'

export const UserEventSourceFactory: IUserEventEmitterFactory = {
    create: (params) => {
        return new UserEventSource({ ...params, logger: Logger.I() })
    }
}

export const DummyEventSourceFactory: IUserEventEmitterFactory = {
    create: (params) => {
        return {
            dispose: async () => {},
            offEvent: () => {},
            onEvent: () => {},
            onError: () => {}
        }
    }
}
