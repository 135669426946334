import { Logger } from './clients/Logger'
import { time } from '@foxtail-dev/datacontracts'

let timeOfNextHeartbeat: Date = new Date(0)
const maxInterval = time.hours(1)
let currentInterval: number

// Logs a heartbeat
// While in foreground, this runs every 30 seconds
// While in the background, the heartbeat is logged on an exponential backoff, starting at 30 seconds and scaling to 1 hour
export const startHeartbeatLogs = (timeMs: number, backgroundRef: React.MutableRefObject<boolean>) => {
    currentInterval = timeMs
    return setInterval(() => {
        if (backgroundRef.current) {
            const now = new Date()
            if (now > timeOfNextHeartbeat) {
                logHeartbeat()
                currentInterval = Math.min(currentInterval * 2, maxInterval)
                timeOfNextHeartbeat = new Date(Date.now() + currentInterval)
            }
        } else {
            logHeartbeat()
            currentInterval = timeMs
            timeOfNextHeartbeat = new Date(Date.now() + timeMs)
        }
    }, timeMs)
}

const logHeartbeat = () => {
    Logger.I().log({
        level: 'info',
        message: 'Heartbeat',
        payload: {
            kind: 'heartbeat',
            entry: {
                internetConnection: navigator.onLine,
                userAgent: navigator.userAgent
            }
        }
    })
}
