import Box from '@mui/material/Box/Box'
import { FoxTypography } from '../components/common/FoxTypography'
import { FoxButton } from '../components/common/FoxButton'
import { NoInternetConnectionIcon } from '../components/icons/NoInternetConnection'
import { Logger } from '../lib/clients/Logger'

export const NoInternet = (): JSX.Element => {
    const reloadPage = () => {
        Logger.I().log({
            level: 'info',
            payload: {
                kind: 'System',
                entry: {
                    action: 'Reload page',
                    source: 'NoInternet'
                }
            }
        })

        window.location.reload()
    }

    return (
        <Box sx={{ justifyContent: 'center', alignItems: 'center', flex: 1, display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ marginTop: '260px', marginBottom: '24px' }}>
                <NoInternetConnectionIcon />
            </Box>
            <FoxTypography variant='h5' sx={{ marginBottom: '17px' }}>
                No Internet
            </FoxTypography>

            <FoxTypography variant='body1' light sx={{ marginBottom: '24px' }}>
                No network connection detected.{`\n`}Check your connection and reload the page.
            </FoxTypography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FoxButton
                    sx={{
                        justifyContent: 'center',
                        marginBottom: '33px',
                        width: '198px',
                        fontWeight: 700,
                        fontSize: '16px',
                        lineHeight: '24px'
                    }}
                    primary
                    text='Reload page'
                    variant='contained'
                    onFoxClick={{ kind: 'button', onClick: reloadPage }}
                />
            </Box>
        </Box>
    )
}
