import { Domains } from '@foxtail-dev/datacontracts/dist/lib/schemas/Domains'
import { Box } from '@mui/material'
import { FoxButton } from '../common/FoxButton'
import { FoxListingImage } from '../common/FoxListingImage'
import { FoxTypography } from '../common/FoxTypography'
import { ListingSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/Listing'
import { useNavigate } from 'react-router-dom'
import { Colors } from '@foxtail-dev/user-clients'

type ListingSoldItemNotificationProps = {
    listing?: ListingSchema
    listingInstanceId: string
    imageId?: string
    logError: (message: string, error?: any) => void
}

export const ListingSoldItemNotification = (props: ListingSoldItemNotificationProps): JSX.Element => {
    const { listingInstanceId, imageId, listing, logError } = props
    const navigate = useNavigate()

    const handleGoToListing = (listingId: string) => {
        navigate(`/app/listings/${listingId}`)
    }

    if (!listing || !imageId || listing.listingDescription.kind === 'draft') {
        return <></>
    }

    if (!listingInstanceId) {
        logError('Listing instance id should exist')
        return <></>
    }

    const listingInstance = listing.listingDescription.listingInstances.find((li) => {
        return li._id === listingInstanceId
    })

    if (!listingInstance) {
        logError('Listing instance should exist')
        return <></>
    }

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <FoxListingImage imageId={imageId} style={styles.productImage} containerStyle={{ ...styles.image }} />
                <Box sx={{ marginTop: '32px', display: 'flex', alignItems: 'center', height: '80px', width: '80px', justifyContent: 'center' }}>
                    <FoxTypography variant='h2' sx={{ color: Colors.light.success, fontWeight: 700 }}>
                        $$
                    </FoxTypography>
                </Box>
            </Box>
            <FoxTypography variant='h5' sx={{ textAlign: 'center', marginTop: '44px', marginLeft: '30px', marginRight: '30px' }}>
                {listing?.listingDescription.listingDetails.commonDetails?.title} has sold
                <br /> on {Domains[listingInstance.domainName].displayName}!
            </FoxTypography>
            <FoxTypography variant='body2' sx={{ textAlign: 'center', marginTop: '32px', marginRight: '80px', marginLeft: '80px' }}>
                You can view your listing summary by going to the listing tab and clicking on the product image.
            </FoxTypography>
            <FoxButton text='View listing' onFoxClick={{ kind: 'button', onClick: async () => handleGoToListing(listing._id) }} primary sx={styles.button} />
        </>
    )
}

const styles = {
    image: {
        height: '80px',
        width: '80px',
        marginTop: '32px',
        borderRadius: '16px',
        marginRight: '40px'
    },
    productImage: {
        height: '80px',
        width: '80px',
        borderRadius: '16px'
    },
    button: {
        marginTop: '48px',
        display: 'flex',
        justifyContent: 'center',
        width: '90%'
    }
}
