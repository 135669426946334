import { LogEntry } from '@foxtail-dev/datacontracts/dist/lib/schemas/logging/LogEntry'
import { UserSchema } from '@foxtail-dev/datacontracts/dist/lib/schemas/users/User'
import { IFaLogger, LoggingClient } from '@foxtail-dev/user-clients'
import { UserHttpClient } from '@foxtail-dev/user-clients/dist/lib/UserHttpClient'
import { Logger as UserClientsLogger } from '@foxtail-dev/user-clients'
import { FoxtailWebConfig } from '../config/FoxtailWebConfig'
import { FoxError, zDateString } from '@foxtail-dev/datacontracts'
import { LogLevel, isLogLevelHigherThanOrEqualTo } from '@foxtail-dev/datacontracts/dist/lib/schemas/logging/LogLevels'
import { SeverityLevel } from '@sentry/react'
import * as Sentry from '@sentry/react'

// TODO: Move to userClients
const FoxToSentryLogLevelMap: Record<LogLevel, SeverityLevel> = {
    [LogLevel.enum.info]: 'info',
    [LogLevel.enum.notice]: 'info',
    [LogLevel.enum.warning]: 'warning',
    [LogLevel.enum.error]: 'error',
    [LogLevel.enum.critical]: 'fatal'
}

// TODO: Export this from user clients
type LoggerUserContextParams = {
    user: UserSchema
    userHttpClient: UserHttpClient
}

// TODO: Set this up using the getter
export class Logger implements IFaLogger {
    // Convert this to a getter
    static #instance: Logger | null = null
    private isDev: boolean

    // When the app has a user, we log using the loggerClient and send things to the backend
    private loggingClient: LoggingClient | null = null
    private readonly flushAtLogLength = 50

    private localLogs: LogEntry[] = []

    private posthogSessionReplayUrl: string | null = null

    private constructor() {
        this.isDev = FoxtailWebConfig.config.env === 'dev'
    }

    static I = (): Logger => {
        if (!Logger.#instance) {
            Logger.#instance = new Logger()
            UserClientsLogger.setLogger(Logger.#instance)
        }

        return Logger.#instance
    }

    log = (logEntry: LogEntry, error?: any) => {
        const entryPayload: any = typeof logEntry.payload.entry === 'object' ? logEntry.payload.entry : {}
        const loggedIn = this.loggingClient !== null
        entryPayload['loggedIn'] = loggedIn
        entryPayload['timestamp'] = zDateString.parse(new Date())
        entryPayload['webVersion'] = FoxtailWebConfig.config.appInfo.version
        entryPayload['platform'] = 'web'

        let sentryId: string | undefined
        if (isLogLevelHigherThanOrEqualTo(logEntry.level, LogLevel.enum.error)) {
            sentryId = Sentry.captureException({
                message: logEntry.message ?? 'No message provided in thrown error',
                error: error,
                serializableObjects: JSON.stringify(
                    {
                        ...logEntry
                    },
                    null,
                    2
                ),
                subErrorKind: 'Fa error log'
            })
        } else if (isLogLevelHigherThanOrEqualTo(logEntry.level, LogLevel.enum.info)) {
            Sentry.addBreadcrumb({
                category: logEntry.payload.kind,
                level: FoxToSentryLogLevelMap[logEntry.level],
                message: logEntry.message ?? logEntry.payload.kind,
                data: entryPayload,
                timestamp: entryPayload.timestamp,
                type: 'info'
            })
        }

        if (sentryId) {
            entryPayload['sentryId'] = sentryId
        }

        if (this.posthogSessionReplayUrl) {
            entryPayload['posthogSessionReplayUrl'] = this.posthogSessionReplayUrl
        }

        const finalLogEntry: LogEntry = {
            ...logEntry,
            payload: {
                ...logEntry.payload,
                entry: entryPayload
            }
        }

        if (this.loggingClient) {
            this.loggingClient.log(finalLogEntry)
        } else {
            this.localLogs.push(finalLogEntry)
        }

        if (this.isDev) {
            console.log(finalLogEntry)
            if (error) {
                console.error(error)
            }
        }
    }

    logT = <T extends LogEntry>(logEntry: T, error?: any) => {
        this.log(logEntry, error)
    }

    setUserContext = (params: LoggerUserContextParams) => {
        Sentry.setUser({
            id: params.user._id,
            email: params.user.userInfo.email
        })

        this.loggingClient = new LoggingClient(params.userHttpClient, {
            flushAtLogLength: this.flushAtLogLength,
            maxByteLength: 94 * 1024,
            handleError: (error) => {
                Sentry.captureException({
                    message: 'Error logging to GCP',
                    category: 'logging',
                    level: 'error',
                    data: {
                        error
                    },
                    timestamp: zDateString.parse(new Date())
                })
            }
        })

        this.localLogs.forEach((log) => {
            this.loggingClient?.log(log)
        })

        this.localLogs = []
    }

    setPosthogSessionReplayUrl = (url: string) => {
        if (this.posthogSessionReplayUrl !== url) {
            this.posthogSessionReplayUrl = url
            this.log({
                level: LogLevel.enum.info,
                payload: {
                    kind: 'System',
                    entry: {
                        posthogSessionReplayUrl: url
                    }
                },
                message: 'Posthog session replay URL set'
            })
        }
    }

    flushLogs = async () => {
        if (this.loggingClient) {
            await this.loggingClient.flush()
        }
    }

    dispose = async () => {
        if (this.loggingClient) {
            await this.loggingClient.dispose() // Flushes logs
        }
        Sentry.setUser(null)
        Logger.#instance = null
    }
}
