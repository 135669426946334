import {
    useAppSelector,
    selectImportListingSessionId,
    selectImportListingItemsAndDetails,
    selectSubscriptionTier,
    getScrapedMarketListings,
    Logger,
    smartSearch,
    ScrapedMarketListingWithRemoved,
    publishImportListingSession,
    cancelImportListingSession,
    deselectImportListingItems,
    selectImportListingItems,
    useAppDispatch,
    canListWithCurrentSubscription,
    determineIfListingLimitReached,
    validateUserCanAct,
    selectHasActiveSubscription,
    checkProgressCompletion,
    selectImportListingSelectedListingsForPublishCount,
    invalidateCachedQueries
} from '@foxtail-dev/user-clients'
import { Box, Divider } from '@mui/material'
import { useState, useMemo, useEffect, useCallback } from 'react'
import { generateToast } from '../../lib/clients/ToastClient'
import { useNavigate } from 'react-router-dom'
import { FoxButton } from '../common/FoxButton'
import { FoxSearchBar } from '../common/FoxSearchBar'
import { ImportListingItemRow } from './ImportListingItemRow'
import { FoxTypography } from '../common/FoxTypography'
import { UpgradeGuardModal } from '../../modals/account/UpgradeGuardModal'
import { FoxCircleLoader } from '../LoadingCircle'
import { FlexGrow } from '../common/FlexGrow'
import { DomainCommonName } from '@foxtail-dev/datacontracts'
import { FixedSizeList, ListChildComponentProps } from 'react-window'

type UserInputImportListingSessionProps = {
    setIsRequestingCancel: React.Dispatch<React.SetStateAction<boolean>>
    setIsPublishing: React.Dispatch<React.SetStateAction<boolean>>
}

export const UserInputImportListingSession = (props: UserInputImportListingSessionProps) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const importListingSessionId = useAppSelector(selectImportListingSessionId)
    const scrapedMarketListings = useAppSelector(selectImportListingItemsAndDetails)
    // TODO add this to user clietnts
    const listingsAttemptingToCreate = useAppSelector(selectImportListingSelectedListingsForPublishCount)
    const subscriptionTier = useAppSelector(selectSubscriptionTier)

    const [isLoadingPublishing, setIsLoadingPublishing] = useState<boolean>(false)
    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState<boolean>(false)
    const hasActiveSubscription = useAppSelector(selectHasActiveSubscription)

    const hasListings = scrapedMarketListings.length > 0
    const [search, setSearch] = useState<string>('')
    const [isPopulatingScrapedListings, setIsPopulatingScrapedListings] = useState<boolean>(true)
    const [isSyntheticLoading, setIsSyntheticLoading] = useState<boolean>(true)

    const closeUpgradeModal = () => {
        setIsUpgradeModalOpen(false)
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsSyntheticLoading(false)
        }, 1000)
        return () => clearTimeout(timeout)
    }, [])

    useEffect(() => {
        const getScrapedMarketListingsAsync = async () => {
            try {
                await dispatch(getScrapedMarketListings()).unwrap()
                setIsPopulatingScrapedListings(false)
            } catch (error) {
                Logger.I().log(
                    {
                        level: 'error',
                        payload: {
                            kind: 'getScrapedMarketListingsError',
                            entry: {
                                importListingSessionId
                            }
                        }
                    },
                    error
                )
                generateToast({ kind: 'info', message: 'Error displaying your listings', subText: 'Please leave and re-enter the page' })
            }
        }

        getScrapedMarketListingsAsync()
    }, [])

    // Filter scrapedMarketListings by search term
    const filteredScrapedMarketListings = useMemo(() => {
        if (search === '') {
            return scrapedMarketListings
        }

        const key = 'scrapedMarketListing.scrapedData.commonDetails.title'
        const results = smartSearch<ScrapedMarketListingWithRemoved>({
            input: search,
            list: scrapedMarketListings,
            keys: [key]
        })

        return results.map((result) => result.item)
    }, [search, scrapedMarketListings])

    const filteredScrapedMarketListingIds = useMemo(() => {
        return filteredScrapedMarketListings.map((scrapedMarketListing) => scrapedMarketListing.scrapedMarketListing._id)
    }, [filteredScrapedMarketListings])

    const areAllSelected = useMemo(() => {
        return filteredScrapedMarketListings.every((scrapedMarketListing) => !scrapedMarketListing.removed)
    }, [filteredScrapedMarketListings])

    const onPublish = async () => {
        setIsLoadingPublishing(true)
        try {
            const listingsAttemptingToCreate = scrapedMarketListings.filter((scrapedMarketListing) => !scrapedMarketListing.removed).length

            const { canList, remaining } = await dispatch(canListWithCurrentSubscription({ listingsAttemptingToCreate })).unwrap()
            const hasReachedListingLimit = determineIfListingLimitReached(subscriptionTier, canList)

            if (hasReachedListingLimit) {
                Logger.I().log({
                    level: 'info',
                    payload: {
                        kind: 'UserValidationError',
                        entry: {
                            validationResult: {
                                canList,
                                remaining
                            },
                            subscriptionTier,
                            form: 'publishImportListingSession'
                        }
                    }
                })

                setIsUpgradeModalOpen(true)
                return
            }

            const validateUserResult = validateUserCanAct({
                hasActiveSubscription,
                caller: 'CreateListingScreen'
            })

            if (!validateUserResult.canAct) {
                Logger.I().log({
                    level: 'info',
                    payload: {
                        kind: 'UserValidationError',
                        entry: {
                            validationResult: validateUserResult,
                            screen: 'ImportListingScreen'
                        }
                    }
                })

                if (validateUserResult.reasons.includes('No active subscription')) {
                    navigate('/inactive-subscription')
                }
                return
            }

            await dispatch(publishImportListingSession()).unwrap()
            Logger.I().log({
                level: 'info',
                message: 'User published import listing',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        importListingSessionId
                    }
                }
            })

            dispatch(invalidateCachedQueries())
            dispatch(checkProgressCompletion({ requestedQuestKinds: ['importExistingListings'] }))

            setIsLoadingPublishing(false)
            props.setIsPublishing(true)
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Error publishing import listing session',
                    payload: {
                        kind: 'publishImportListingSessionError',
                        entry: {
                            importListingSessionId
                        }
                    }
                },
                error
            )
            generateToast({ kind: 'error', message: 'Error publishing listings', subText: 'Please try again' })
            setIsLoadingPublishing(false)
        }
    }

    // setIsRequestingCancel opens the confirmation modal
    // If we don't have listings, we don't confirm and instead just cancel the import
    const onCancel = async () => {
        if (hasListings) {
            props.setIsRequestingCancel(true)
        } else {
            try {
                Logger.I().log({
                    level: 'info',
                    message: 'User cancelled import listing session because no scraped listings were found',
                    payload: {
                        kind: 'UserAction',
                        entry: {
                            importListingSessionId
                        }
                    }
                })
                await dispatch(cancelImportListingSession()).unwrap()
            } catch (error) {
                Logger.I().log(
                    {
                        level: 'error',
                        message: 'Error cancelling import listing session',
                        payload: {
                            kind: 'ImportListingCancelError',
                            entry: {
                                importListingSessionId
                            }
                        }
                    },
                    error
                )
            }
            navigate('/app/listings')
        }
    }

    const onDeselectAll = async () => {
        dispatch(deselectImportListingItems(filteredScrapedMarketListingIds))
    }

    const onSelectAll = async () => {
        dispatch(selectImportListingItems(filteredScrapedMarketListingIds))
    }

    const memoizedHeader = useMemo(() => {
        return (
            <>
                <FoxSearchBar label='Search listings' value={search} onChange={setSearch} sx={styles.searchBar} />
            </>
        )
    }, [search, setSearch, areAllSelected, onDeselectAll, onSelectAll])

    const renderItem = useCallback(
        ({ index, style }: ListChildComponentProps<string>) => {
            const scrapedMarketListingId = filteredScrapedMarketListingIds[index] ?? ''
            return (
                <div style={style}>
                    <ImportListingItemRow key={scrapedMarketListingId} scrapedMarketListingId={scrapedMarketListingId} />
                </div>
            )
        },
        [filteredScrapedMarketListingIds]
    )

    return isPopulatingScrapedListings || isSyntheticLoading ? (
        <Box sx={styles.containerLoading}>
            <FlexGrow />

            <Box sx={styles.containerLoadingText}>
                <FoxCircleLoader />
                <FoxTypography variant='h6' sx={styles.textLoading}>
                    Populating your imported listings
                </FoxTypography>
            </Box>
            <FlexGrow />
            <FoxButton
                primary
                sx={styles.buttonCancelImport}
                text='Cancel import'
                onFoxClick={{
                    kind: 'button',
                    onClick: async () => {
                        props.setIsRequestingCancel(true)
                    }
                }}
            />
        </Box>
    ) : hasListings ? (
        <Box>
            {memoizedHeader}
            <Box sx={styles.containerImportItems}>
                <FixedSizeList height={700} itemCount={filteredScrapedMarketListingIds.length} itemSize={96} width='100%'>
                    {renderItem}
                </FixedSizeList>
            </Box>
            <Divider sx={{ marginTop: '12px' }} />
            <Box sx={styles.containerButtons}>
                {areAllSelected ? (
                    <FoxButton grey onFoxClick={{ kind: 'button', onClick: onDeselectAll }} text='Deselect all' style={styles.button} />
                ) : (
                    <FoxButton grey onFoxClick={{ kind: 'button', onClick: onSelectAll }} text='Select all' style={styles.button} />
                )}
                <FoxButton sx={{ marginLeft: '14px' }} grey onFoxClick={{ kind: 'button', onClick: onCancel }} text='Cancel' style={styles.button} />
                <FoxButton
                    sx={{ marginLeft: '14px' }}
                    primary
                    onFoxClick={{ kind: 'button', onClick: onPublish, preventDoubleClick: true }}
                    loading={isLoadingPublishing}
                    text={`Import (${listingsAttemptingToCreate})`}
                    style={styles.button}
                />
            </Box>
            <UpgradeGuardModal open={isUpgradeModalOpen} onClose={closeUpgradeModal} reason='listingLimit' markets={DomainCommonName.options} />
        </Box>
    ) : (
        <Box sx={styles.containerAlreadyTracking}>
            <FoxTypography variant='h6' sx={styles.textAlreadyTracking}>
                All active listings are already being tracked by Foxtail
            </FoxTypography>
            <FoxButton
                primary
                sx={styles.buttonCancelImport}
                text='Cancel import'
                onFoxClick={{
                    kind: 'button',
                    onClick: onCancel
                }}
            />
        </Box>
    )
}

const styles = {
    containerImportItems: {
        height: '600px',
        overflow: 'auto',
        marginLeft: '30px'
    },
    containerButtons: {
        marginTop: '12px',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '20px',
        marginBottom: '13px'
    },
    containerLoading: {
        height: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        padding: '20px'
    },
    containerLoadingText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column'
    },
    containerAlreadyTracking: {
        height: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column',
        padding: '20px'
    },
    textAlreadyTracking: {
        textAlign: 'center',
        marginLeft: '80px',
        marginRight: '80px',
        flex: 1,
        alignItems: 'center',
        display: 'flex'
    },
    textLoading: {
        textAlign: 'center',
        marginLeft: '80px',
        marginRight: '80px',
        flex: 1,
        alignItems: 'center',
        display: 'flex',
        marginTop: '30px'
    },
    buttonCancelImport: {
        width: '100%',
        justifyContent: 'center'
    },
    button: {
        width: '120px',
        justifyContent: 'center'
    },
    searchBar: {
        width: '100%',
        marginBottom: '40px',
        padding: '20px'
    }
}
