import { useEffect, useRef } from 'react'
import * as Sentry from '@sentry/react'
import { FoxError } from '@foxtail-dev/datacontracts'
import { toastClient } from '../lib/clients/ToastClient'
import { Logger } from '../lib/clients/Logger'

// Tap 10 times to flush logs and trigger a fake Sentry error
// This is useful for debugging in production
export const FOX_DEBUGGER = () => {
    const tapCountRef = useRef<number>(0)
    const timeoutIdRef = useRef<number | null>(null)

    useEffect(() => {
        const handleInteraction = () => {
            tapCountRef.current += 1

            if (timeoutIdRef.current !== null) {
                clearTimeout(timeoutIdRef.current)
            }

            if (tapCountRef.current >= 10) {
                flushLogs()
                tapCountRef.current = 0
            } else {
                timeoutIdRef.current = window.setTimeout(() => {
                    tapCountRef.current = 0
                    timeoutIdRef.current = null
                }, 1000)
            }
        }

        window.addEventListener('touchstart', handleInteraction)
        window.addEventListener('click', handleInteraction)

        return () => {
            window.removeEventListener('touchstart', handleInteraction)
            window.removeEventListener('click', handleInteraction)

            if (timeoutIdRef.current !== null) {
                clearTimeout(timeoutIdRef.current)
            }
        }
    }, [])

    const flushLogs = () => {
        Logger.I().log({
            level: 'info',
            payload: {
                kind: 'System'
            },
            message: 'Flushing logs - triggered by quick tapping'
        })

        Logger.I().flushLogs()

        Sentry.flush(2000)

        const fakeError = new FoxError({ message: 'Debugging error triggered by tap/click gesture.', serializableObjects: {} })
        Sentry.captureException(fakeError)

        toastClient.generateToast({ kind: 'info', message: 'Debug info sent to Foxtail AI - triggered by quick tapping' })
    }

    return <></>
}
