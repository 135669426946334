import { ListingDetails, ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { useCallback, useEffect, useState } from 'react'
import { CreateListingCommonFields } from '../../modals/listingDetails/CreateListingCommonFields'
import { Autosaver } from '../../components/formik/Autosaver'
import {
    sanitizeZodObject,
    CreateListingFormDraftValidationSchema,
    Logger,
    useAppDispatch,
    useAppSelector,
    selectIsAutosaving,
    CreateListingFormValidationSchema,
    areDomainDetailsDifferentThanDefault,
    sanitizeDomainSpecificDetails,
    setRuleFailureFormikState,
    validateListingDetails,
    validateUserCanAct,
    selectCurrentEbayConditions,
    selectCurrentEtsyAttributes,
    selectHasActiveSubscription,
    getSelectedMarkets,
    loadListingDetails,
    initializeListingDetails,
    publishListingDetails,
    saveListingDetailDraft,
    getDomainLinkingStatus,
    canListWithCurrentSubscription,
    selectSubscriptionTier,
    selectListingDetailInitialized,
    deleteListingDraft,
    clearListingDetails,
    selectIsAiGeneratingByListingId,
    determineIfListingLimitReached,
    checkProgressCompletion,
    selectSetUpRequiredDomains,
    selectLoggedInDomains,
    invalidateCachedQueries
} from '@foxtail-dev/user-clients'
import { CreateListingDomainSpecificFields } from '../../modals/listingDetails/CreateListingDomainSpecificFields'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { assert, DomainCommonName, z } from '@foxtail-dev/datacontracts'
import { generateToast } from '../../lib/clients/ToastClient'
import { useTaxonomyProvider } from '../../lib/TaxonomyProvider'
import { Box } from '@mui/material'
import { FlexGrow } from '../../components/common/FlexGrow'
import { FoxButton } from '../../components/common/FoxButton'
import { FoxTypography } from '../../components/common/FoxTypography'
import { shouldDisplayErrorMessage } from '../../utils/shouldDisplayErrorMessage'
import { UpgradeGuardModal } from '../../modals/account/UpgradeGuardModal'
import { CreateListingAiGenerateStage } from '../../modals/listingDetails/CreateListingAiGenerateStage'
import { myObjectDeepStrictEqual } from '../../utils/objectComparison'
import { CreateListingSkeleton } from '../../components/skeletons/CreateListingSkeleton'
import { ConnectAndSetupMarketsBeforeListingModal } from '../../modals/listing/ConnectAndSetupMarketsBeforeListingModal'
import { setDomainSpecificSkus } from '../../modals/domainSpecific/DomainSpecificFormikUtils'
import { valuesIn } from 'lodash'

export const CreateListingScreenStage = z.enum(['commonDetails', 'aiGenerateDetails', 'marketDetails'])
export type CreateListingScreenStage = z.infer<typeof CreateListingScreenStage>

const defaultListingDetails: ListingDetailsDraft = {
    commonDetails: {
        tags: [] // listingDetails requires that the array is here, even if it's empty
    }
}

export const CreateListingScreen = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const params = useParams()
    const taxonomyProvider = useTaxonomyProvider()

    const stage = queryParams.get('stage')

    const changeStage = (stage: CreateListingScreenStage, replace: boolean = false) => {
        const newParams = new URLSearchParams(location.search)
        newParams.set('stage', stage)
        navigate(`${location.pathname}?${newParams.toString()}`, { replace })
    }

    useEffect(() => {
        const parseResult = CreateListingScreenStage.safeParse(queryParams.get('stage'))

        if (parseResult.success) {
            setCurrentStage(parseResult.data)
        } else {
            changeStage('commonDetails', true)
        }
    }, [stage])

    const [initialListingDetails, setInitialListingDetails] = useState<ListingDetailsDraft | null>(null)
    const isAutosaving = useAppSelector(selectIsAutosaving)
    const hasActiveSubscription = useAppSelector(selectHasActiveSubscription)
    const currentEbayConditions = useAppSelector(selectCurrentEbayConditions)
    const currentEtsyAttributes = useAppSelector(selectCurrentEtsyAttributes)
    const subscriptionTier = useAppSelector(selectSubscriptionTier)
    const initialized = useAppSelector(selectListingDetailInitialized)
    const loggedInAndReadyToListDomains = useAppSelector(selectLoggedInDomains)
    const setupRequiredDomains = useAppSelector(selectSetUpRequiredDomains)
    const listingId = params.listingId

    const [saving, setSaving] = useState<boolean>(false)
    const [currentStage, setCurrentStage] = useState<CreateListingScreenStage>('commonDetails')
    const [justAutosaved, setJustAutosaved] = useState<boolean>(true)
    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState<boolean>(false)
    const [loadingDescription, setLoadingDescription] = useState(false)
    const [loadingTags, setLoadingTags] = useState(false)
    const isAiGenerating = useAppSelector(selectIsAiGeneratingByListingId(listingId ?? '')) ?? false
    const [isLoadingSynthetic, setIsLoadingSynthetic] = useState(true)
    const [marketsNeedingLinking, setMarketsNeedingLinking] = useState<DomainCommonName[]>([])
    const [marketsNeedingSetup, setMarketsNeedingSetup] = useState<DomainCommonName[]>([])

    const closeUpgradeModal = () => {
        setIsUpgradeModalOpen(false)
    }

    const [connectAndSetupMarketsBeforeListingModal, setConnectAndSetupMarketsBeforeListingModal] = useState<boolean>(false)

    const initializeListingAsync = async () => {
        try {
            // TODO: This should also return the initial listing details
            const listingId = await dispatch(initializeListingDetails()).unwrap()
            dispatch(invalidateCachedQueries())

            Logger.I().log({
                level: 'info',
                message: 'User initialized listing',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        listingId
                    }
                }
            })
            navigate(`/app/create-listing/${listingId}?stage=commonDetails`, { replace: true })
            setInitialListingDetails(defaultListingDetails)
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Failed to load listing details',
                    payload: {
                        kind: 'CreateListingError',
                        entry: {}
                    }
                },
                error
            )
            generateToast({ kind: 'info', message: 'Unable to create listing', subText: 'Please try again later' })
        }
    }

    const loadListingAsync = async (listingId: string) => {
        try {
            const loadedListing = await dispatch(loadListingDetails(listingId)).unwrap()
            setInitialListingDetails(loadedListing.listingDescription.listingDetails)

            Logger.I().log({
                level: 'info',
                message: 'User loaded listing details',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        listing: loadedListing
                    }
                }
            })
        } catch (error) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Failed to load listing details',
                    payload: {
                        kind: 'CreateListingError',
                        entry: {
                            listingId
                        }
                    }
                },
                error
            )
            // TODO: Show error screen
            generateToast({ kind: 'info', message: 'Unable to create listing', subText: 'Please try again later' })
        }
    }

    useEffect(() => {
        if (initialListingDetails) {
            return
        }

        if (listingId === 'new' || !listingId) {
            initializeListingAsync()
        } else {
            loadListingAsync(listingId)
        }
    }, [listingId, initialListingDetails])

    useEffect(() => {
        setTimeout(() => {
            setIsLoadingSynthetic(false)
        }, 1000)
    }, [])

    useEffect(() => {
        const domainLinkingInterval = setInterval(() => {
            dispatch(getDomainLinkingStatus())
        }, 5000)
        return () => clearInterval(domainLinkingInterval)
    }, [])

    useEffect(() => {
        if (isAiGenerating && stage !== 'aiGenerateDetails') {
            changeStage('aiGenerateDetails')
        }
    }, [isAiGenerating, stage])

    useEffect(() => {
        let timer: NodeJS.Timeout

        if (justAutosaved) {
            timer = setTimeout(() => {
                setJustAutosaved(false)
            }, 5000)
        }

        return () => {
            clearTimeout(timer)
        }
    }, [justAutosaved])

    // Autosave runs 2 seconds after the user stops typing, saving only the fields that parse validation
    const onAutosave = useCallback(
        async (currentValues: ListingDetailsDraft) => {
            // TODO: Is this the right validation schema? Should it change based on the stage?
            const sanitizedListingDetails = sanitizeZodObject<CreateListingFormDraftValidationSchema>(currentValues, CreateListingFormDraftValidationSchema)
            // Reparse to get it as the right type, also ensures that the sanitize worked
            const result = CreateListingFormDraftValidationSchema.safeParse(sanitizedListingDetails)
            if (result.success) {
                try {
                    await dispatch(saveListingDetailDraft({ listingDetailsDraft: result.data, fromAutosave: true })).unwrap()
                    Logger.I().log({
                        level: 'info',
                        message: 'Autosaved listing details',
                        payload: {
                            kind: 'SystemAction',
                            entry: {
                                listingId
                            }
                        }
                    })
                    setJustAutosaved(true)
                } catch (error) {
                    Logger.I().log(
                        {
                            level: 'error',
                            message: 'Failed to autosave listing details',
                            payload: {
                                kind: 'AutosaveError',
                                entry: {
                                    listingId,
                                    listingDetails: result.data
                                }
                            }
                        },
                        error
                    )
                }
            } else {
                Logger.I().log(
                    {
                        level: 'error',
                        message: 'Failed to sanitize listing details for autosave',
                        payload: {
                            kind: 'AutosaveError',
                            entry: {
                                listingId,
                                zodParseErrorMessage: result.error.message,
                                zodParseErrorName: result.error.name,
                                form: 'CreateListingForm'
                            }
                        }
                    },
                    result.error
                )
            }
        },
        [setJustAutosaved, listingId]
    )

    const publish = async (listingDetails: ListingDetails) => {
        await dispatch(publishListingDetails(listingDetails)).unwrap()

        Logger.I().log({
            level: 'info',
            message: 'User posted listing',
            payload: {
                kind: 'UserAction',
                entry: {
                    listingId,
                    listingDetails
                }
            }
        })

        dispatch(invalidateCachedQueries())
        dispatch(checkProgressCompletion({ requestedQuestKinds: ['listAnItem'] }))

        navigate('/app/listings')
    }

    const onPublish = async (values: ListingDetailsDraft, formikHelpers: FormikHelpers<ListingDetailsDraft>) => {
        const { setFieldError, setFieldTouched, setStatus } = formikHelpers
        const selectedMarkets = getSelectedMarkets(values)

        if (loadingDescription || loadingTags || !taxonomyProvider) {
            return
        }

        const isLoggedInAndReadyToListOnAllSelectedMarkets = selectedMarkets.every((domainName) => loggedInAndReadyToListDomains.includes(domainName))

        if (!isLoggedInAndReadyToListOnAllSelectedMarkets) {
            const selectedMarketsThatAreNotLoggedInAndReady = selectedMarkets.filter((domain) => !loggedInAndReadyToListDomains.includes(domain))

            const marketsNeedingSetup: DomainCommonName[] = selectedMarketsThatAreNotLoggedInAndReady.filter((domainName) =>
                setupRequiredDomains.includes(domainName)
            )
            const marketsNeedingLinking: DomainCommonName[] = selectedMarketsThatAreNotLoggedInAndReady.filter(
                (domainName) => !marketsNeedingSetup.includes(domainName)
            )
            setMarketsNeedingLinking(marketsNeedingLinking)
            setMarketsNeedingSetup(marketsNeedingSetup)
            setConnectAndSetupMarketsBeforeListingModal(true)

            return
        }

        try {
            Logger.I().log({
                level: 'info',
                message: 'User is attempting to post listing',
                payload: {
                    kind: 'UserAction',
                    entry: {
                        listingId,
                        listingDetails: values
                    }
                }
            })

            const listingsAttemptingToCreate = 1

            const { canList, remaining } = await dispatch(canListWithCurrentSubscription({ listingsAttemptingToCreate })).unwrap()

            const hasReachedListingLimit = determineIfListingLimitReached(subscriptionTier, canList)
            if (hasReachedListingLimit) {
                Logger.I().log({
                    level: 'info',
                    payload: {
                        kind: 'UserValidationError',
                        entry: {
                            listingId,
                            listingDetails: values,
                            validationResult: {
                                canList,
                                remaining
                            },
                            subscriptionTier,
                            screen: 'CreateListingScreen'
                        }
                    }
                })

                setIsUpgradeModalOpen(true)
                return
            }

            const validateUserResult = validateUserCanAct({
                hasActiveSubscription,
                caller: 'CreateListingScreen'
            })

            if (!validateUserResult.canAct) {
                Logger.I().log({
                    level: 'info',
                    payload: {
                        kind: 'UserValidationError',
                        entry: {
                            listingId,
                            listingDetails: values,
                            validationResult: validateUserResult,
                            screen: 'CreateListingScreen'
                        }
                    }
                })

                if (validateUserResult.reasons.includes('No active subscription')) {
                    navigate('/inactive-subscription')
                }
                return
            }

            const listingDetailsValidationResult = validateListingDetails({
                values,
                marketsToPublishTo: selectedMarkets,
                currentEbayConditions,
                currentEtsyAttributes,
                caller: 'ReviewListingForm',
                taxonomies: taxonomyProvider
            })

            if (listingDetailsValidationResult.hasError) {
                setRuleFailureFormikState({
                    ruleFailures: listingDetailsValidationResult.failedRules,
                    setFieldError,
                    setFieldTouched,
                    setStatus,
                    createStatusString: !listingDetailsValidationResult.failedParsing
                })

                return
            }

            const listingDetails = listingDetailsValidationResult.listingDetails
            assert(listingDetails, 'listingDetails should be defined')

            await publish(listingDetails)
        } catch (error: any) {
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Failed to post listing',
                    payload: {
                        kind: 'PublishListingError',
                        entry: {
                            listingId,
                            listingDetails: values
                        }
                    }
                },
                error
            )

            generateToast({ kind: 'info', message: 'Unable to post listing' })
        }
    }

    return !initialListingDetails || !listingId || isLoadingSynthetic || !taxonomyProvider ? (
        <FoxModalDialog leftButtonKind={'close'} open={true} title='New listing' onClose={() => {}}>
            <CreateListingSkeleton />
        </FoxModalDialog>
    ) : (
        <Formik initialValues={initialListingDetails} onSubmit={onPublish} validateOnBlur={false}>
            {({
                values,
                handleSubmit,
                isSubmitting,
                setFieldError,
                setFieldValue,
                setFieldTouched,
                setErrors,
                touched,
                errors,
                status
            }: FormikProps<ListingDetailsDraft>) => {
                // TODO: Move saveDraft out of formik and pass in the values and FormikHelpers instead
                // TODO: Think about whether the validation schemas should change based on the stage
                const _saveDraft = async (params: { isDraft: boolean }) => {
                    if (saving) {
                        return
                    }

                    setSaving(true)

                    Logger.I().log({
                        level: 'info',
                        message: 'User is attempting to save listing details draft',
                        payload: {
                            kind: 'UserAction',
                            entry: {
                                listingId,
                                isDraft: params.isDraft,
                                listingDetails: values
                            }
                        }
                    })

                    // This object may already contain values that are edited on the next page (ReviewListingForm)
                    // We only save the ones that pass validation, and ignore the values that fail
                    // In the future, there may be other details that need to be sanitized.  At the moment, they are all in the domainSpecificDetails property
                    const sanitizedListingDetailsDraft = sanitizeDomainSpecificDetails(values)

                    const result = params.isDraft
                        ? CreateListingFormDraftValidationSchema.safeParse(sanitizedListingDetailsDraft)
                        : CreateListingFormValidationSchema.safeParse(sanitizedListingDetailsDraft)

                    if (!result.success) {
                        result.error.issues.forEach((issue) => {
                            setFieldError(issue.path.join('.'), issue.message)
                            setFieldTouched(issue.path.join('.'))
                        })
                        Logger.I().log({
                            level: 'info',
                            message: 'Listing details draft failed to parse validation schema',
                            payload: {
                                kind: 'UserValidationError',
                                entry: {
                                    form: 'CreateListingForm',
                                    listingId,
                                    listingDetails: sanitizedListingDetailsDraft,
                                    zodErrorMessage: result.error.message,
                                    zodErrorName: result.error.name
                                }
                            }
                        })
                    } else {
                        try {
                            await dispatch(
                                saveListingDetailDraft({
                                    listingDetailsDraft: result.data,
                                    fromAutosave: false
                                })
                            ).unwrap()
                            Logger.I().log({
                                level: 'info',
                                message: 'User saved listing details draft',
                                payload: {
                                    kind: 'UserAction',
                                    entry: {
                                        listingId,
                                        isDraft: params.isDraft,
                                        listingDetails: result.data
                                    }
                                }
                            })
                        } catch (error) {
                            Logger.I().log(
                                {
                                    level: 'error',
                                    message: 'Failed to save listing details draft',
                                    payload: {
                                        kind: 'SaveDraftError',
                                        entry: {
                                            listingId,
                                            listingDetails: result.data
                                        }
                                    }
                                },
                                error
                            )
                            generateToast({ kind: 'info', message: 'Failed to save listing details draft' })
                            return false
                        }
                    }

                    setSaving(false)
                    return result.success
                }

                const closeConnectAndSetupMarketsBeforeListingModal = async (params: { result: boolean; currentValues: ListingDetailsDraft }) => {
                    if (params.result) {
                        await _saveDraft({ isDraft: true })
                        navigate('/app/listings?tab=draft')
                    }
                }

                const onSaveDraft = async () => {
                    const shouldDelete = initialized && myObjectDeepStrictEqual(defaultListingDetails, values)

                    Logger.I().log({
                        level: 'info',
                        message: 'User clicked close button on listing details',
                        payload: {
                            kind: 'UserAction',
                            entry: {
                                listingId,
                                listingDetails: values,
                                shouldDelete
                            }
                        }
                    })

                    try {
                        if (shouldDelete) {
                            await dispatch(deleteListingDraft(listingId)).unwrap()
                        } else {
                            await _saveDraft({ isDraft: true })
                        }
                    } catch (error) {
                        Logger.I().log(
                            {
                                level: 'error',
                                message: 'Failed to save or delete listing details draft',
                                payload: {
                                    kind: 'SaveOrDeleteDraftError',
                                    entry: {
                                        listingId,
                                        listingDetails: values,
                                        shouldDelete
                                    }
                                }
                            },
                            error
                        )
                        const errorMessage = shouldDelete ? 'Failed to delete empty listing' : 'Failed to save listing'
                        generateToast({ kind: 'info', message: errorMessage })
                    }

                    dispatch(clearListingDetails())
                    navigate('/app/listings?tab=draft')
                }

                const onContinue = async () => {
                    setErrors({})

                    const saveDraftSuccessful = await _saveDraft({ isDraft: false })

                    if (saveDraftSuccessful) {
                        if (values.domainSpecificDetails && areDomainDetailsDifferentThanDefault(values.domainSpecificDetails)) {
                            changeStage('marketDetails')
                        } else {
                            setDomainSpecificSkus(setFieldValue, setFieldTouched, values)
                            changeStage('aiGenerateDetails')
                        }
                    }
                }

                const onGoToDomainSpecificDetails = () => {
                    changeStage('marketDetails')
                }

                const onGoToCommonDetails = () => {
                    changeStage('commonDetails')
                }

                const isCommonDetailsStage = currentStage === 'commonDetails'

                return (
                    <FoxModalDialog
                        leftButtonKind={isCommonDetailsStage ? 'close' : 'back'}
                        open={true}
                        title='New listing'
                        onClose={isCommonDetailsStage ? onSaveDraft : onGoToCommonDetails}>
                        <Autosaver debounceMs={5000} onSave={onAutosave} isSubmitting={isSubmitting} />

                        {currentStage === 'commonDetails' ? (
                            <CreateListingCommonFields values={values} listingId={listingId} />
                        ) : currentStage === 'aiGenerateDetails' ? (
                            <CreateListingAiGenerateStage
                                isGenerating={isAiGenerating}
                                values={values}
                                listingId={listingId}
                                onCancel={onGoToCommonDetails}
                                onContinue={onGoToDomainSpecificDetails}
                            />
                        ) : (
                            <CreateListingDomainSpecificFields
                                values={values}
                                listingId={listingId}
                                setLoadingDescription={setLoadingDescription}
                                setLoadingTags={setLoadingTags}
                                loadingDescription={loadingDescription}
                                loadingTags={loadingTags}
                            />
                        )}

                        {status && (
                            <Box sx={styles.containerValidation}>
                                <FoxTypography variant='body1' danger sx={styles.textError}>
                                    {status}
                                </FoxTypography>
                            </Box>
                        )}

                        {shouldDisplayErrorMessage(touched, errors) && (
                            <Box sx={styles.containerValidation}>
                                <FoxTypography variant='body1' danger sx={styles.textError}>
                                    Fix form errors to post listing
                                </FoxTypography>
                            </Box>
                        )}
                        <UpgradeGuardModal open={isUpgradeModalOpen} onClose={closeUpgradeModal} reason='listingLimit' markets={DomainCommonName.options} />

                        <Box sx={{ ...styles.actionButtonContainer }}>
                            {justAutosaved && (
                                <FoxTypography variant={'body1'} light>
                                    Draft Autosaved
                                </FoxTypography>
                            )}

                            <FlexGrow />
                            <FoxButton
                                variant='contained'
                                size='large'
                                text='Save draft'
                                loading={saving}
                                disabled={isSubmitting || saving || isAutosaving}
                                onFoxClick={{ kind: 'button', onClick: onSaveDraft }}
                                sx={{ marginRight: '16px', fontWeight: 'bold' }}
                                grey
                            />
                            <FoxButton
                                primary
                                variant='contained'
                                size='large'
                                text={isCommonDetailsStage ? 'Continue' : 'Submit'}
                                loading={isSubmitting}
                                disabled={isSubmitting || saving || isAutosaving}
                                onFoxClick={{
                                    kind: 'button',
                                    onClick: isCommonDetailsStage ? onContinue : async () => await handleSubmit(),
                                    preventDoubleClick: true
                                }}
                                sx={{ fontWeight: 'bold' }}
                            />
                        </Box>
                        <ConnectAndSetupMarketsBeforeListingModal
                            open={connectAndSetupMarketsBeforeListingModal}
                            onClose={closeConnectAndSetupMarketsBeforeListingModal}
                            marketsNeedingLinking={marketsNeedingLinking}
                            marketsNeedingSetup={marketsNeedingSetup}
                            currentValues={values}
                        />
                    </FoxModalDialog>
                )
            }}
        </Formik>
    )
}

const styles = {
    subSectionHeader: {
        marginTop: '24px',
        marginBottom: '12px',
        fontWeight: 'bold'
    },
    subSectionContainer: {
        paddingLeft: '8px'
    },
    actionButtonContainer: {
        marginTop: '12px',
        display: 'flex',
        alignItems: 'center'
    },
    containerValidation: {
        marginLeft: '4px',
        marginTop: '4px'
    },
    textError: {
        fontSize: '12px',
        lineHeight: '16px'
    }
}
