import {
    AppConfig,
    IAuth0Client,
    IIntercomClient,
    INetworkClient,
    INotificationClient,
    IPlatformClient,
    FoxtailWebExtensionClient,
    UserRuntimeContextProvider
} from '@foxtail-dev/user-clients'
import * as Intercom from '@intercom/messenger-js-sdk'
import { BASE_FOXTAIL_DIRECTORY, DummyFs, WebDesktopFileSystem } from '../../lib/clients/FileSystem'
import { DummyImageClientFactory, ImageClientFactory } from '../../lib/clients/ImageClientFactory'
import { toastClient } from '../../lib/clients/ToastClient'
import { FoxtailWebConfig } from '../../lib/config/FoxtailWebConfig'
import { Logger } from '../../lib/clients/Logger'
import { getMessaging, getToken } from 'firebase/messaging'
import { VideoClientFactory } from '../../lib/clients/VideoClientFactory'
import { Auth0RawClient } from '../../lib/clients/Auth0RawClient'
import { IUserEventEmitterFactory } from '@foxtail-dev/user-clients/dist/lib/UserEventSource'
import { DummyEventSourceFactory, UserEventSourceFactory } from '../../lib/clients/UserEventSourceFactory'

type InitializeUserRuntimeContextProviderAsyncParams = {
    setUserRuntimeContextProviderInitialized: React.Dispatch<React.SetStateAction<boolean>>
    getAccessTokenSilently: () => Promise<string>
    clearCredentials: () => Promise<void>
    isMobile: boolean
}
export const initializeUserRuntimeContextProviderAsync = async (params: InitializeUserRuntimeContextProviderAsyncParams) => {
    const { setUserRuntimeContextProviderInitialized, getAccessTokenSilently, clearCredentials } = params

    const appConfig: AppConfig = {
        connectionInfo: {
            host: FoxtailWebConfig.config.foxtailApiGateway.host,
            port: FoxtailWebConfig.config.foxtailApiGateway.port,
            testHost: FoxtailWebConfig.config.foxtailApiGateway.host
        },
        env: FoxtailWebConfig.config.env
    }

    const auth0Client: IAuth0Client = {
        getUserInfo: async () => {
            const auth0User = await Auth0RawClient.getUser()

            return {
                email: auth0User?.email ?? ''
            }
        },
        getCredentialsAndRefresh: async () => {
            const token = await getAccessTokenSilently()
            return {
                accessToken: token
            }
        },
        clearCredentials: clearCredentials
    }

    const intercomClient: IIntercomClient = {
        loginUserWithUserAttributes: (params) => {
            Intercom.default({
                app_id: FoxtailWebConfig.config.intercomAppId,
                user_id: params.userId, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
                name: params.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
                email: params.email,
                user_hash: params.userHash // get from userClients method
            })
        },
        logout: async () => {
            // TODO see if this is needed
        }
    }

    const networkClient: INetworkClient = {
        getNetInfo: async () => {
            return {
                isConnected: navigator.onLine,
                userAgent: navigator.userAgent
            }
        }
    }

    const notificationClient: INotificationClient = {
        getToken: async () => {
            const messaging = getMessaging()
            const token = await getToken(messaging, { vapidKey: FoxtailWebConfig.config.firebase.vapid })

            return token ?? 'noWebTokenFound'
            // if (token) {
            //     return token
            // } else {
            //     // Show permission request UI
            //     return (
            //         Notification.requestPermission()
            //             .then(async permission => {
            //                 if (permission === 'granted') {
            //                     return await getToken(messaging, { vapidKey: FoxtailWebConfig.config.firebase.vapid })
            //                 }
            //             })
            //             .catch(error => {
            //                 Logger.I().log(
            //                     {
            //                         level: 'error',
            //                         payload: {
            //                             kind: 'notificationClient.getToken',
            //                             entry: {}
            //                         },
            //                         message: 'error getToken after requesting Permission'
            //                     },
            //                     error
            //                 )
            //             }) ?? 'noWebTokenFound'
            //     )
            // }
        },
        hasPermission: async () => {
            // TODO: Implement this
            return false
        }
    }

    const platformClient: IPlatformClient = {
        getPlatform: () => {
            return 'web'
        },
        isWebMobile: () => {
            return params.isMobile
        }
    }

    const logger = Logger.I()
    const imageClientFactory = params.isMobile ? new DummyImageClientFactory() : new ImageClientFactory()

    const videoClientFactory = new VideoClientFactory()

    const fileSystem = params.isMobile ? DummyFs : await WebDesktopFileSystem.create(BASE_FOXTAIL_DIRECTORY)

    const foxtailWebExtensionClient = FoxtailWebExtensionClient.create()

    const eventSourceFactory: IUserEventEmitterFactory = params.isMobile ? DummyEventSourceFactory : UserEventSourceFactory

    UserRuntimeContextProvider.setPlatformSpecificParams({
        appConfig,
        auth0Client,
        directory: 'foxtail/',
        fs: fileSystem,
        imageClientFactory,
        videoClientFactory,
        intercomClient,
        logger,
        networkClient,
        notificationClient,
        platformClient,
        toastClient,
        extensionClient: foxtailWebExtensionClient,
        eventSourceFactory
    })

    setUserRuntimeContextProviderInitialized(true)
}
