import React from 'react'
import { Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom'
import HomeScreen from '../screens/home/HomeScreen'
import { Auth0Callback } from '../components/Auth0Callback'
import { selectIsAuthenticated, useAppSelector } from '@foxtail-dev/user-clients'
import { SandboxScreen } from '../screens/SandboxScreen'
import { AppLayout } from '../layouts/AuthenticatedLayout'
import { ListingsScreen } from '../screens/listing/ListingsScreen'
import BasicInfoScreen from '../screens/onboarding/BasicInfoScreen'
import SignUpCompleteScreen from '../screens/onboarding/SignUpCompleteScreen'
import AccountLayout from '../layouts/AccountLayout'
import MarketConnectionsScreen from '../screens/account/MarketConnectionsScreen'
import ProfileScreen from '../screens/account/ProfileScreen'
import NotificationSettingsScreen from '../screens/account/NotificationSettingsScreen'
import SubscriptionScreen from '../screens/account/SubscriptionScreen'
import { SignInScreen } from '../screens/SignInScreen'
import UnauthenticatedLayout from '../layouts/UnAuthenticatedLayout'
import { NotificationsScreen } from '../screens/home/NotificationsScreen'
import { PageNotFoundScreen } from '../screens/PageNotFoundScreen'
import { NoInternet } from '../screens/NoInternet'
import { ListingSummaryScreen } from '../screens/listing/ListingSummaryScreen'
import { CreateListingScreen } from '../screens/listing/CreateListingScreen'
import { UpcomingListingScreen } from '../screens/home/UpcomingListingsScreen'
import { SubscriptionSuccessScreen } from '../screens/SubscriptionSuccessScreen'
import { EditListingScreen } from '../screens/listing/EditListingScreen'
import { InactiveSubscriptionScreen } from '../screens/InactiveSubscriptionScreen'
import { EnterPhoneNumberScreen } from '../screens/onboarding/EnterPhoneNumberScreen'
import { VerifyPhoneNumberScreen } from '../screens/onboarding/VerifyPhoneNumberScreen'
import { ListingsFromVideoScreen } from '../screens/listing/ListingsFromVideoScreen'
import { MobileDownloadScreen } from '../screens/MobileDownloadScreen'
import { ImportListingScreen } from '../screens/listing/ImportListingScreen'
import { SubscriptionMobileScreen } from '../screens/account/SubscriptionMobileScreen'
import { BulkCrosslistScreen } from '../screens/bulkCrosslist/BulkCrosslistScreen'
import EngagementBoostersScreen from '../screens/engagementBoosters/EngagementBoostersScreen'
import { ProgressChecklistScreen } from '../screens/account/ProgressChecklistScreen'
import { SignUpScreen } from '../screens/SignUpScreen'
import { useMediaQuery } from '@mui/material'
import MobileLayout from '../layouts/MobileLayout'
import { BulkCrosslistReviewScreen } from '../screens/bulkCrosslist/BulkCrosslistReviewScreen'
import { BulkCrosslistEditItemScreen } from '../screens/bulkCrosslist/BulkCrosslistEditItemScreen'
import ListingDefaultsScreen from '../screens/account/ListingDefaultsScreen'
import { FoxtailWebConfig } from '../lib/config/FoxtailWebConfig'

const NavigateToHome: React.FC = () => <Navigate to='/app/home' replace />
const NavigateToSignIn: React.FC = () => <Navigate to='/signin' replace />

const MobileRedirect: React.FC = () => {
    window.location.href = 'foxtailai://'
    return null
}

const RootNavigator: React.FC = () => {
    const isAuthenticated = useAppSelector(selectIsAuthenticated)
    const isMobile = useMediaQuery('(max-width:600px)')

    const isDev = FoxtailWebConfig.config.env === 'dev'
    const SandBoxIfDev: React.FC = isDev ? () => <SandboxScreen /> : () => <Navigate to='/app/home' replace />

    const router = createBrowserRouter([
        {
            path: '/',
            element: <UnauthenticatedLayout />,
            children: isAuthenticated
                ? [
                      {
                          path: 'app',
                          element: isMobile ? <MobileLayout /> : <AppLayout />,
                          children: [
                              { path: 'home', element: <HomeScreen /> },
                              { path: 'processing', element: <UpcomingListingScreen /> },
                              { path: 'profile', element: <ProfileScreen /> },
                              { path: 'notifications', element: <NotificationsScreen /> },
                              { path: 'create-listing/:listingId', element: <CreateListingScreen /> },
                              { path: 'edit-listing/:listingId', element: <EditListingScreen /> },
                              { path: 'import-listing', element: <ImportListingScreen /> },
                              { path: 'bulk-crosslist', element: <BulkCrosslistScreen /> },
                              { path: 'bulk-crosslist/review', element: <BulkCrosslistReviewScreen /> },
                              { path: 'bulk-crosslist/edit-item/:listingId', element: <BulkCrosslistEditItemScreen /> },
                              { path: 'listings-from-video', element: <ListingsFromVideoScreen /> },
                              {
                                  path: 'account',
                                  element: <AccountLayout />,
                                  children: [
                                      { path: 'marketplace-connections', element: <MarketConnectionsScreen /> },
                                      { path: 'profile', element: <ProfileScreen /> },
                                      { path: 'notification-settings', element: <NotificationSettingsScreen /> },
                                      { path: 'crosslisting-defaults', element: <ListingDefaultsScreen /> },
                                      { path: 'subscription', element: <SubscriptionScreen /> },
                                      { path: 'checklist', element: <ProgressChecklistScreen /> },
                                      { path: '', element: <Navigate to='marketplace-connections' replace /> },
                                      { path: '*', element: <Navigate to='marketplace-connections' replace /> }
                                  ]
                              },
                              { path: 'listings', element: <ListingsScreen /> },
                              { path: 'listings/:listingId', element: <ListingSummaryScreen /> },
                              { path: 'engagement-boosters', element: <EngagementBoostersScreen /> }
                          ]
                      },
                      { path: 'mobile', element: <MobileDownloadScreen /> },
                      { path: 'subscription-mobile', element: <SubscriptionMobileScreen /> },
                      { path: 'enter-phone-number', element: <EnterPhoneNumberScreen /> },
                      { path: 'verify-phone-number', element: <VerifyPhoneNumberScreen /> },
                      { path: 'basic-info', element: <BasicInfoScreen /> },
                      { path: 'sign-up-complete', element: <SignUpCompleteScreen /> },
                      { path: 'subscription-success', element: <SubscriptionSuccessScreen /> },
                      { path: 'page-not-found', element: <PageNotFoundScreen /> },
                      { path: 'no-internet', element: <NoInternet /> },
                      { path: 'sandbox', element: <SandBoxIfDev /> },
                      { path: 'inactive-subscription', element: <InactiveSubscriptionScreen /> },
                      { path: '', element: <NavigateToHome /> },
                      { path: '*', element: <NavigateToHome /> }
                  ]
                : [
                      { path: 'signin', element: <SignInScreen /> },
                      { path: 'signup', element: <SignUpScreen /> },
                      { path: 'sandbox', element: <SandBoxIfDev /> },
                      { path: 'callback', element: <Auth0Callback /> },
                      { path: 'mobile-redirect', element: <MobileRedirect /> },
                      { path: '', element: <NavigateToSignIn /> },
                      { path: '*', element: <NavigateToSignIn /> }
                  ]
        },
        { path: 'page-not-found', element: <PageNotFoundScreen /> },
        { path: '', element: <NavigateToHome /> },
        { path: '*', element: <NavigateToHome /> }
    ])

    return <RouterProvider router={router} />
}

export default RootNavigator
