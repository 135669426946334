import { useAppSelector, selectCurrentEbayReturnPolicy } from '@foxtail-dev/user-clients'
import { useState } from 'react'
import { DomainSpecificDetailModalChildProps } from '../../containers/forms/domainSpecificFields/DomainSpecificDetailModal'
import { updateEbayReturnPolicyInFormik } from './DomainSpecificFormikUtils'
import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { useFormikContext } from 'formik'
import { EbayReturnPolicyDetailSelection } from '../../containers/forms/listingDetails/EbayReturnPolicyDetailSelection'
import { EbayReturnPolicyCard } from '../../containers/forms/listingDetails/EbayReturnPolicyCard'

export const EbayReturnPolicyModal = ({ closeModal }: DomainSpecificDetailModalChildProps) => {
    const currentlySelectedEbayReturnPolicy = useAppSelector(selectCurrentEbayReturnPolicy)
    const [selectedPolicyId, setSelectedPolicyId] = useState(currentlySelectedEbayReturnPolicy ?? '')

    const { setFieldValue, setFieldTouched } = useFormikContext<ListingDetailsDraft>()

    const handleSave = async (returnPolicyId: string) => {
        updateEbayReturnPolicyInFormik(setFieldValue, setFieldTouched, returnPolicyId)
        closeModal()
    }

    return (
        <EbayReturnPolicyDetailSelection
            title='Return Policy'
            handleSave={async () => await handleSave(selectedPolicyId)}
            renderItem={item => {
                return (
                    <EbayReturnPolicyCard
                        key={item.returnPolicyId}
                        cardPolicy={item.returnPolicyId}
                        currentPolicySelection={selectedPolicyId ?? ''}
                        title={item.name}
                        setPolicy={() => setSelectedPolicyId(item.returnPolicyId)}
                        returnPolicyId={item.returnPolicyId}
                    />
                )
            }}
        />
    )
}
