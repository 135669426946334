import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { FoxtailWebConfig } from '../lib/config/FoxtailWebConfig'
import { Logger, setCredentials, useAppDispatch } from '@foxtail-dev/user-clients'
import { Box, useMediaQuery } from '@mui/material'
import { FoxCircleLoader } from './LoadingCircle'

/**
 * this is called in one of potentially two ways
 * 1. post signin with the callback
 * 2. post navigation to the app before loading is complete where we try to get the access token from auth0, we put a timeout on this in case there is no access token? - That is in App.tsx
 *
 */

/**
 * we should only hit this from auth0 post user login
 */
export const Auth0Callback: React.FC = () => {
    const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const isMobile = useMediaQuery('(max-width:600px)')

    const handleAuth = async () => {
        try {
            Logger.I().log({
                level: 'info',
                payload: {
                    kind: 'Auth0'
                },
                message: 'On the auth0 callback page, about to get the access token'
            })
            const token = await getAccessTokenSilently({
                authorizationParams: {
                    audience: FoxtailWebConfig.config.auth0.audience,
                    scope: 'openid profile email offline_access'
                }
            })

            if (token) {
                Logger.I().log({
                    level: 'info',
                    payload: {
                        kind: 'Auth0'
                    },
                    message: 'Access token found, beginning login process'
                })
                dispatch(setCredentials({ credentials: { accessToken: token } }))
                navigate('/app/home')
            } else {
                Logger.I().log({
                    level: 'info',
                    payload: {
                        kind: 'Auth0'
                    },
                    message: 'No access token found'
                })
                navigate('/signin')
            }
        } catch (error) {
            navigate('/signin')
        }
    }

    useEffect(() => {
        if (isAuthenticated && !isLoading) {
            handleAuth()
        }
    }, [isAuthenticated, isLoading])

    return (
        <Box sx={styles.container(isMobile)}>
            <FoxCircleLoader size={60} />
        </Box>
    )
}

const styles = {
    container: (isMobile: boolean) => ({
        background: 'white',
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: isMobile ? '10px' : '0'
    })
}
