import { ListingDetailsDraft } from '@foxtail-dev/datacontracts/dist/lib/schemas/listings/ListingDetails'
import { generateListingDetailsUsingAi, getSelectedMarkets, selectListingDetails, useAppDispatch, useAppSelector } from '@foxtail-dev/user-clients'
import { Box, Link } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { FoxModalDialog } from '../../components/common/FoxModalDialog'
import { FoxTypography } from '../../components/common/FoxTypography'
import { FoxButton } from '../../components/common/FoxButton'
import { AiGenerateModal } from './AiGenerateModal'
import { Logger } from '../../lib/clients/Logger'
import { assert } from '@foxtail-dev/datacontracts'
import { generateToast } from '../../lib/clients/ToastClient'
import { useFormikContext } from 'formik'
import { AiGuyIcon } from '../../components/icons/AIGuy'
import { SparkleIcon } from '../../components/icons/SparkleIcon'

type CreateListingAiGenerateStageProps = {
    isGenerating: boolean
    values: ListingDetailsDraft
    listingId: string
    onCancel: () => void
    onContinue: () => void
}

export const CreateListingAiGenerateStage = ({ isGenerating, values, listingId, onCancel, onContinue }: CreateListingAiGenerateStageProps) => {
    const dispatch = useAppDispatch()

    const initiallyGenerating = useRef(isGenerating)
    const [createListingWithAiModalOpen, setCreateListingWithAiModalOpen] = useState(!isGenerating)
    const [aiGenerateModalOpen, setAiGenerateModalOpen] = useState(isGenerating)
    const [aiGenerationPromise, setAiGenerationPromise] = useState<Promise<ListingDetailsDraft | undefined> | null>(null)
    const listingDetailsInRedux = useAppSelector(selectListingDetails)

    const { setValues } = useFormikContext<ListingDetailsDraft>()

    const onContinueWithAI = async () => {
        setCreateListingWithAiModalOpen(false)
        setAiGenerateModalOpen(true)
        await generateListingDetailsUsingAiAsync(values, listingId)
    }
    const onContinueWithoutAI = async () => {
        setCreateListingWithAiModalOpen(false)
        onContinue()
    }

    // Handles the case where a person leaves while the ai generation is happening and then comes back
    // The changes will be in redux, we need to set them in the formik context when the ai generation is finished
    useEffect(() => {
        if (initiallyGenerating.current && !isGenerating) {
            setValues(listingDetailsInRedux)
            onContinue()
        }
    }, [isGenerating, initiallyGenerating, listingDetailsInRedux])

    // Handles the case where a person stays on the screen while the ai generation is happening
    // We wait for the ai generation to finish and then set the values in the formik context
    useEffect(() => {
        let isMounted = true
        if (aiGenerationPromise) {
            aiGenerationPromise
                .then((listingDetails) => {
                    if (!isMounted) {
                        return
                    }

                    if (listingDetails) {
                        setValues(listingDetails)
                    }

                    Logger.I().log({
                        level: 'info',
                        message: 'Finished generating listing details using AI',
                        payload: {
                            kind: 'GenerateListingDetailsUsingAi',
                            entry: {
                                listingId
                            }
                        }
                    })

                    onContinue()
                })
                .catch((error) => {
                    // Because the function is set and forget, it does the logging and generates a toast itself
                })
        }

        return () => {
            if (aiGenerationPromise) {
                isMounted = false
                setAiGenerationPromise(null)
            }
        }
    }, [aiGenerationPromise])

    const generateListingDetailsUsingAiAsync = async (listingDetails: ListingDetailsDraft, listingId: string) => {
        const selectedDomains = getSelectedMarkets(listingDetails)

        try {
            const commonDetails = listingDetails.commonDetails
            const domainSpecificDetails = listingDetails.domainSpecificDetails
            assert(domainSpecificDetails, 'domainSpecificDetails should be defined')

            assert(commonDetails, 'commonDetails should be defined')
            const title = commonDetails.title
            assert(title, 'title should be defined')
            const imageIds = listingDetails.imageIds
            assert(imageIds, 'imageIds should be defined')

            const aiGenerationResultPromise = dispatch(
                generateListingDetailsUsingAi({
                    createListingDetailsFromMinimalParams: {
                        domains: selectedDomains,
                        title,
                        listingId
                    },
                    foxtailSku: listingDetails.foxtailSku,
                    commonDetails: commonDetails,
                    imageIds
                })
            ).unwrap()

            setAiGenerationPromise(aiGenerationResultPromise)
        } catch (error) {
            generateToast({ kind: 'info', message: 'Failed to generate listing details using AI' })
            Logger.I().log(
                {
                    level: 'error',
                    message: 'Failed to generate listing details using AI',
                    payload: {
                        kind: 'GenerateListingDetailsUsingAiError',
                        entry: {
                            listingId,
                            domains: selectedDomains
                        }
                    }
                },
                error
            )
            onCancel()
        }
    }

    return (
        <Box>
            <FoxModalDialog
                open={createListingWithAiModalOpen}
                title={'Autofill listing details with AI'}
                leftButtonKind={'back'}
                onClose={onCancel}
                removeOverlay
                actions={
                    <Box>
                        <FoxButton
                            primary
                            onFoxClick={{ kind: 'button', onClick: onContinueWithAI, preventDoubleClick: true }}
                            text={'Use AI to autofill'}
                            sx={{ paddingLeft: '12px', marginRight: '12px' }}
                            showBetaChip
                            startIcon={<SparkleIcon color={'white'} />}
                        />
                        <FoxButton grey onFoxClick={{ kind: 'button', onClick: onContinueWithoutAI }} text={'Enter details manually'} />
                    </Box>
                }>
                <Box sx={{ textAlign: 'center' }}>
                    <AiGuyIcon height={158} width={120} />
                    <FoxTypography variant='body2' sx={{ marginBottom: '8px' }}>
                        You can always review and edit details after they're generated.{' '}
                    </FoxTypography>
                    <FoxTypography variant='body1' sx={{ marginBottom: '8px' }} light>
                        <Link
                            sx={{ textDecoration: 'none' }}
                            href='https://help.foxtail.ai/en/articles/9246521-what-is-autofill-ai'
                            target='_blank'
                            rel='noopener'>
                            Click here to learn more.
                        </Link>
                    </FoxTypography>
                </Box>
            </FoxModalDialog>
            <AiGenerateModal open={aiGenerateModalOpen} />
        </Box>
    )
}
