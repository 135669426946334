import { BoxProps, Box, Icon, useTheme } from '@mui/material'
import React from 'react'
import { FoxTypography } from '../../../components/common/FoxTypography'
import { sharedStyles } from '../../../theme/SharedStyling'
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material'
import { makeCustomFoxtailPolicyPretty } from '@foxtail-dev/user-clients'
import { FoxButtonBase } from '../../../components/common/FoxButtonBase'

interface EbayReturnPolicyProps {
    setPolicy: (subscriptionTier: string) => void
    currentPolicySelection: string
    cardPolicy: string
    title?: string
    returnPolicyId: string
    sx?: BoxProps['sx']
}

export const EbayReturnPolicyCard = (props: EbayReturnPolicyProps) => {
    const theme = useTheme()

    const active = props.currentPolicySelection === props.cardPolicy

    // TODO: Figure out what bounceable should be

    return (
        <FoxButtonBase
            onFoxClick={{ kind: 'button', onClick: async () => props.setPolicy(props.cardPolicy) }}
            sx={{
                ...sharedStyles.flex,
                ...styles.container,
                ...{
                    borderColor: active && !props.cardPolicy ? theme.palette.info.main : theme.palette.divider,
                    backgroundColor: theme.palette.background.default
                },
                ...props.sx
            }}>
            <Box sx={{ marginBottom: '8px', flexGrow: 1 }}>
                <FoxTypography variant='body2' sx={styles.containerTitle}>
                    {makeCustomFoxtailPolicyPretty(props.title)}
                </FoxTypography>
            </Box>
            <Box sx={[styles.containerIcon]}>{active ? <RadioButtonChecked /> : <RadioButtonUnchecked />}</Box>
        </FoxButtonBase>
    )
}

const styles = {
    container: {
        marginBottom: '16px',
        width: '100%',
        alignItems: 'center',
        borderWidth: '2px',
        borderStyle: 'solid',
        borderRadius: '10px'
    },
    containerTitle: {
        marginTop: '8px',
        marginLeft: '16px',
        lineHeight: '26px'
    },
    containerPrice: {
        marginLeft: '16px',
        marginTop: '8px'
    },
    containerIcon: {
        width: '36px'
    },
    textPrice: {
        marginLeft: '28px',
        marginBottom: '16px'
    },
    shippingDetail: {
        marginLeft: '28px'
    },
    shippingServiceOptions: {
        marginLeft: 48
    },
    textPerMonth: {
        marginLeft: 3,
        marginTop: 18,
        fontSize: 12,
        lineHeight: 16
    },
    containerTextCurrentPlan: {
        height: 23,
        width: 89,
        marginRight: 23,
        borderRadius: 12,
        marginTop: 65
    },
    textCurrentPlan: {
        fontSize: 12,
        lineHeight: 16
    }
}
