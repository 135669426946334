import {
    useAppDispatch,
    useAppSelector,
    Logger,
    selectCurrentEbayReturnPolicy,
    getEbayReturnPoliciesVerbose
} from '@foxtail-dev/user-clients'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { FoxTypography } from '../../../components/common/FoxTypography'
import { sharedStyles } from '../../../theme/SharedStyling'
import { FoxButton } from '../../../components/common/FoxButton'
import { ReturnPolicy } from '@foxtail-dev/datacontracts/dist/lib/schemas/domainSpecific/ebay/response/ReturnPolicy'

export type CategorySelectionProps = {
    title: string
    renderItem: (item: any) => JSX.Element
    handleSave: () => Promise<void>
}

export const EbayReturnPolicyDetailSelection = ({ title, renderItem, handleSave }: CategorySelectionProps) => {
    const dispatch = useAppDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const [returnPolicies, setReturnPolicies] = useState<ReturnPolicy[]>([])
    const currentlySelectedEbayReturnPolicy = useAppSelector(selectCurrentEbayReturnPolicy)

    useEffect(() => {
        setIsLoading(true)
        const getReturnPolicies = async () => {
            try {
                const returnPolicies = await dispatch(getEbayReturnPoliciesVerbose()).unwrap()
                const currentReturnPolicy = returnPolicies.find(i => i.returnPolicyId === currentlySelectedEbayReturnPolicy)
                if (!currentReturnPolicy || currentReturnPolicy === undefined) {
                    Logger.I().log({
                        level: 'error',
                        message: 'Could not find current return policy',
                        payload: {
                            kind: 'NoDefaultReturnPolicyFound'
                        }
                    })
                }

                // reorder fulfillment policies so that current policy is first
                const reorderedPolicies = returnPolicies.filter(i => i.returnPolicyId !== currentlySelectedEbayReturnPolicy)

                if (currentReturnPolicy) {
                    reorderedPolicies.unshift(currentReturnPolicy)
                }

                setReturnPolicies(reorderedPolicies)
                setIsLoading(false)
            } catch (error) {
                Logger.I().log(
                    {
                        level: 'error',
                        message: 'Error getting return policies',
                        payload: {
                            kind: 'ErrorGettingReturnPolicies'
                        }
                    },
                    error
                )
                setIsLoading(false)
            }
        }
        getReturnPolicies()
    }, [])

    return (
        <Box sentry-label='EbayReturnPolicyDetailSelectionScreen'>
            {isLoading ? (
                <FoxTypography variant='h6' light sx={{ textAlign: 'center', marginTop: '140px', marginHorizontal: '20px' }}>
                    Syncing your Return Policies from eBay
                </FoxTypography>
            ) : returnPolicies?.length === 0 ? (
                <Box sx={sharedStyles.flex1}>
                    <FoxTypography variant='h6' light sx={{ textAlign: 'center', marginBottom: '28px', marginTop: '140px' }}>
                        We didn't find any Return Policies on your account. Please add them manually on eBay and come back. {`\n\n`}If you need more help visit
                        our support center.
                    </FoxTypography>
                    <Box sx={sharedStyles.flexGrow} />
                </Box>
            ) : (
                <Box sx={sharedStyles.flex1}>
                    {returnPolicies?.map(i => renderItem(i))}
                    <Box sx={sharedStyles.flexGrow} />
                    <FoxTypography variant='body1' light sx={{ textAlign: 'center', marginBottom: '12px', marginTop: '12px' }}>
                        If you don't see your Return Policy here or want to add a new Return Policy, you must manually add it to your eBay account and click the
                        Sync return policies button below.
                    </FoxTypography>
                    <Box sx={{ marginTop: '36px' }}>
                        <FoxButton
                            primary
                            onFoxClick={{
                                kind: 'button',
                                onClick: async () => {
                                    await handleSave()
                                },
                                preventDoubleClick: true
                            }}
                            text='Save'
                            sx={{ justifyContent: 'center', marginRight: '16px' }}
                        />
                        <FoxButton
                            grey
                            onFoxClick={{
                                kind: 'button',
                                onClick: async () => {
                                    try {
                                        setIsLoading(true)
                                        const allPolicies = await dispatch(getEbayReturnPoliciesVerbose()).unwrap()
                                        setReturnPolicies(allPolicies)
                                        setIsLoading(false)
                                    } catch (error) {
                                        Logger.I().log(
                                            {
                                                level: 'error',
                                                message: 'Error getting return policies',
                                                payload: {
                                                    kind: 'ErrorGettingReturnPolicies'
                                                }
                                            },
                                            error
                                        )
                                        setIsLoading(false)
                                    }
                                },
                                preventDoubleClick: true
                            }}
                            text='Sync return policies'
                        />
                    </Box>
                </Box>
            )}
        </Box>
    )
}
