import React, { useEffect, useMemo, useRef, useState } from 'react'
import RootNavigator from './router/RootNavigator'
import {
    checkProgressCompletion,
    selectAppLoaded,
    selectHydratedQuestsSorted,
    selectUserId,
    setIsAppInBackground,
    useAppDispatch,
    useAppSelector
} from '@foxtail-dev/user-clients'
import { useAuth0 } from '@auth0/auth0-react'
import { Toaster } from 'react-hot-toast'
import { LoadingScreen } from './screens/LoadingScreen'
import { Box, useMediaQuery } from '@mui/material'
import { initializeUserRuntimeContextProviderAsync } from './utils/appInit/initializeUserRuntimeContextProviderAsync'
import { initializeAppAsync } from './utils/appInit/initializeAppAsync'
import { setTaxonomyProviderAsync } from './utils/appInit/setTaxonomyProviderAsync'
import { createLogoutUrl } from './utils/createLogoutUrl'
import { FoxtailWebConfig } from './lib/config/FoxtailWebConfig'
import { setGAUserInformation } from './utils/appInit/setGAUserInformation'
import { usePostHog } from 'posthog-js/react'
import { setPosthogUserInformation } from './utils/appInit/setPosthogUserInformation'
import { Logger } from './lib/clients/Logger'
import { time } from '@foxtail-dev/datacontracts'
import { startHeartbeatLogs } from './lib/logHeartbeat'
import { UserQuestKind } from '@foxtail-dev/datacontracts/dist/lib/schemas/userProgress/UserQuest'
import { FOX_GOD_MODE } from './debug/FOX_GOD_MODE'
import { NoInternet } from './screens/NoInternet'
import { FOX_DEBUGGER } from './debug/FOX_DEBUGGER'

const App: React.FC = () => {
    const dispatch = useAppDispatch()
    const { getAccessTokenSilently, logout, isAuthenticated, isLoading } = useAuth0()
    const isMobile = useMediaQuery('(max-width:600px)')

    const [userRuntimeContextProviderInitialized, setUserRuntimeContextProviderInitialized] = useState(false)
    const appLoaded = useAppSelector(selectAppLoaded)
    const [finishedReloginProcess, setFinishedReloginProcess] = useState(false)
    const [hasInternet, setHasInternet] = useState(navigator.onLine)
    const quests = useAppSelector(selectHydratedQuestsSorted)
    const foxtailUserId = useAppSelector(selectUserId)
    const posthog = usePostHog()
    const backgroundRef = useRef<boolean>(document.visibilityState === 'hidden')

    const areQuestsAvailable = useMemo(() => {
        return quests.some((quest) => quest.quest.details.status === 'active')
    }, [quests])

    useEffect(() => {
        initializeUserRuntimeContextProviderAsync({
            getAccessTokenSilently,
            clearCredentials: () =>
                logout({
                    logoutParams: {
                        returnTo: createLogoutUrl(window.location.origin, 'logout')
                    },
                    clientId: FoxtailWebConfig.config.auth0.clientId
                }),
            setUserRuntimeContextProviderInitialized,
            isMobile
        })
    }, [])

    useEffect(() => {
        if (userRuntimeContextProviderInitialized && !appLoaded && !isLoading) {
            if (isAuthenticated) {
                initializeAppAsync({ setFinishedReloginProcess, dispatch, getAccessTokenSilently })
            } else {
                setFinishedReloginProcess(true)
            }
        }
    }, [userRuntimeContextProviderInitialized, appLoaded, isAuthenticated, isLoading])

    useEffect(() => {
        if (appLoaded) {
            // TODO: Call reset on this information on logout
            setGAUserInformation(foxtailUserId)
            setPosthogUserInformation(posthog, foxtailUserId)
            setFinishedReloginProcess(true)
            setTaxonomyProviderAsync()
        }
    }, [appLoaded])

    useEffect(() => {
        if (appLoaded && areQuestsAvailable) {
            dispatch(checkProgressCompletion({ requestedQuestKinds: UserQuestKind.options }))
        }
    }, [appLoaded, areQuestsAvailable])

    useEffect(() => {
        const handleVisibilityChange = () => {
            Logger.I().log({
                level: 'info',
                payload: {
                    kind: 'System',
                    entry: {
                        visibilityState: document.visibilityState
                    }
                },
                message: 'Page visibility state changed'
            })

            const isAppInBackground = document.visibilityState === 'hidden'

            dispatch(setIsAppInBackground(isAppInBackground))
            backgroundRef.current = isAppInBackground
        }

        document.addEventListener('visibilitychange', handleVisibilityChange)

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange)
        }
    }, [])

    const updateOnlineStatus = () => {
        Logger.I().log({
            level: 'info',
            payload: {
                kind: 'System',
                entry: {
                    online: navigator.onLine
                }
            },
            message: `Online status changed from ${hasInternet} to ${navigator.onLine}`
        })

        setHasInternet(navigator.onLine)
    }

    useEffect(() => {
        window.addEventListener('online', updateOnlineStatus)
        window.addEventListener('offline', updateOnlineStatus)

        return () => {
            window.removeEventListener('online', updateOnlineStatus)
            window.removeEventListener('offline', updateOnlineStatus)
        }
    }, [])

    useEffect(() => {
        startHeartbeatLogs(time.seconds(30), backgroundRef)
    }, [])

    return (
        <Box>
            {hasInternet ? finishedReloginProcess ? <RootNavigator /> : <LoadingScreen /> : <NoInternet />}
            <Toaster position='top-right' reverseOrder={false} />
            <FOX_DEBUGGER />
            {FoxtailWebConfig.config.foxGodMode && <FOX_GOD_MODE />}
        </Box>
    )
}

export default App
