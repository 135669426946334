import { exposeFoxtailAccessToken } from '../../lib/clients/foxtailWebExtensionClient/exposeFoxtailAccessToken'
import { AppDispatch, setCredentials } from '@foxtail-dev/user-clients'
import { Logger } from '../../lib/clients/Logger'

type InitializeAppAsyncParams = {
    setFinishedReloginProcess: React.Dispatch<React.SetStateAction<boolean>>
    dispatch: AppDispatch
    getAccessTokenSilently: () => Promise<string>
}

export const initializeAppAsync = async (params: InitializeAppAsyncParams) => {
    const { setFinishedReloginProcess, dispatch, getAccessTokenSilently } = params

    try {
        Logger.I().log({
            level: 'info',
            payload: {
                kind: 'System'
            },
            message: 'Attempting to relogin - getting access token silently'
        })
        const accessToken = await getAccessTokenSilently()
        exposeFoxtailAccessToken(accessToken)
        dispatch(setCredentials({ credentials: { accessToken } }))
    } catch (error) {
        Logger.I().log({
            level: 'info',
            payload: {
                kind: 'System'
            },
            message: 'Failed to get access token silently'
        })
        setFinishedReloginProcess(true)
    }
}
